
import styled from 'styled-components'
import { colors } from '../style'

export const TextField = ({ placeholder, width, onChange }) => {
    return (
        <StyledTextField type='text' onChange={onChange} width={width} placeholder={placeholder} />
    )
}

export const TextArea = ({ placeholder, width, rows }) => {
    return (
        <StyledTextArea type='text' width={width} placeholder={placeholder} rows={rows | '14'} />
    )
}

export const StyledTextField = styled.input`
    background-color: ${colors.lightgray};
    border: 1px solid ${colors.lightgray};
    outline: none;
    padding: 18px 22px;
    font-weight: 500;
    font-size: 13px;
    font-family: 'Montserrat', sans-serif;

    margin-bottom: 15px;
    width: ${props => props.width};

    transition: all 0.2s;

    &:focus {
        border: 1px solid ${colors.yellow};
        background: white;
    }
`

export const StyledTextArea = styled.textarea`
    background-color: ${colors.lightgray};
    border: 1px solid ${colors.lightgray};
    outline: none;
    padding: 18px 22px;
    font-weight: 500;
    font-size: 13px;
    font-family: 'Montserrat', sans-serif;

    margin-bottom: 15px;
    width: ${props => props.width};

    transition: all 0.2s;

    resize: none;

    &:focus {
        border: 1px solid ${colors.yellow};
        background: white;
    }
`