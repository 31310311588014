import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styled from "styled-components"
import { ratings } from "../data"
import { faBolt, faThumbsUp, faUser } from '@fortawesome/free-solid-svg-icons'

import { colors } from "../style"

import React, { useState } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 2,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };


//   const NewSlider = () => {
//     return (
//         <AwesomeSlider cssModule={null} style={{ height: '200px' }} bullets={false}>
//             <div className="halläää" style={{ display: 'flex !important', justifyContent: 'center'}}>
//                 <div>Kachel 1</div>
//                 <div>Kachel 2</div>
//             </div>
//             <div style={{ display: 'flex'}}>
//                 <div>Kachel 3</div>
//                 <div>Kachel 4</div>
//             </div>
//         </AwesomeSlider>
//     );
//   };

const NewSlider = () => {
    return(
        <SliderSection>
            <Slider {...settings}>
               {ratings.map(rating => {
                    return <RatingCard text={rating.text} author={rating.author}/>
               })}
            </Slider>
        </SliderSection>
    )
  };
  
  
  export default NewSlider;
  
  const RatingCard = ({text, author}) => {
    return (
        <StyledRatingCard>
            <div style={{color: colors.yellow, fontSize: '14px', marginBottom: '5px'}}>
                <FontAwesomeIcon icon={faBolt}/><FontAwesomeIcon icon={faBolt}/><FontAwesomeIcon icon={faBolt}/><FontAwesomeIcon icon={faBolt}/><FontAwesomeIcon icon={faBolt}/>
            </div>
            <Testimonial>{text}</Testimonial>
            <Author>
                {}
                <FontAwesomeIcon icon={faUser}/>
                <span style={{paddingLeft: '7px'}}>{author}</span>
            </Author>
        </StyledRatingCard>
    )
}

const Wrapper = styled.div`
    /* display: flex;
    flex-wrap: wrap;
    justify-content: center; */
    margin-bottom: 25px;
    font-family: "Lato", sans-serif;
`

const SliderSection = styled.div`
    width: 85%;
    margin: 0 auto;
    cursor: grab;
    .slick-slider{
        width: 90%;
        margin: 0 auto;
    }
    /* @media (max-width: 600px) {
        .slick-slide {
            display: flex;
            justify-content: center;
        }
    } */
    .slick-slide > div {
        margin: 0 10px;
    }
    .slick-list {
    }
    .slick-prev:before {
        content: "<";
        color: ${colors.yellow};
        font-size: 30px;
    }

    .slick-next:before {
        content: ">";
        color: ${colors.yellow};
        font-size: 30px;
    }
`

const StyledRatingCard = styled.div`
    /* width: 250px; */
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1), -2px -2px 6px rgba(0, 0, 0, 0.05);
    padding: 14px;
    border-radius: 4px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: white;
    display: inline-block;
`

const Testimonial = styled.div`
    margin: 10px 0 14px 0;
`

const Author = styled.div`
    font-size: 14px;
    color: ${colors.gray};
`