import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { YellowButton } from  '../components/Buttons'
import styled from 'styled-components'
import { faPhone, faMapMarkerAlt, faEnvelope, faBolt } from '@fortawesome/free-solid-svg-icons'
import { colors, StyledH1, StyledH2 } from '../style'
import { StyledTextField, StyledTextArea } from '../components/Inputs'
import { useState } from 'react'
import emailjs from 'emailjs-com'
import { sendMail } from '../handlers/sendMail'
import { email, mobile, phone } from '../data'
import {Helmet} from "react-helmet";

export default function Contact() {

    const [formData, setFormData] = useState({
        name: 'Tim',
        email: 'TIMMYB12@GMX.DE',
        message: 'Hallo'
    })

    const handleSubmitForm = (e) => {
        e.preventDefault()
        sendMail(formData)
    }

    const ContactPoint = ({icon, heading, subheading}) => {
        return (
            <div className="contactPoint">
                <div className="iconContainer">
                    <FontAwesomeIcon icon={icon} />
                </div>
                <div className="textContainer">
                    <div className="heading">{heading}</div>
                    <div className="subheading">{subheading}</div>
                </div>
            </div> 
        )
    }

    return (
        <div className='contact'>
            <Helmet>
                <meta name="description" content="Nehmen Sie jetzt mit uns Kontakt auf! Wir sind Ihr Elektriker im Umkreis Bad Hersfeld bei allen Fragen zu Solar, Wallboxen oder Elektroinstallationen." />
                <title>Kontakt – BSH ELEKTRO</title>
                <link rel="canonical" href="https://www.bsh-elektro.de/kontakt" />
            </Helmet>
            <StyledH1>Kontakt</StyledH1>
            <ContactContainer>
                <div>
                    <p style={{color: colors.darkgray, fontSize: '20px', textAlign: 'center'}}>
                        Wir haben immer ein offenes Ohr für Ihr Anliegen <br/> – und garantiert auch die passende Lösung.
                        <br/>
                        <br/> 
                        <br/> 
                        <FontAwesomeIcon color={colors.yellow} icon={faBolt}/>
                        <br/>
                        <br/>
                    </p>
                </div>
                <StyledH2>Sprechen Sie uns an!</StyledH2>
                <MessageSectionForm action="contactMailer.php" method="post">
                    <div style={{width: '100%', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap'}}>
                        <StyledTextField type='text' name='name' required placeholder='NAME*' onChange={(e) => setFormData({...formData, name: e.target.value})} />
                        <StyledTextField type='email' name='email' required placeholder='E-MAIL*' onChange={(e) => setFormData({...formData, email: e.target.value})} />
                    </div>
                    <StyledTextArea type='text' name='message' required placeholder='IHRE NACHRICHT*' width={'100%'} rows='14' onChange={(e) => setFormData({...formData, message: e.target.value})} />
                    <YellowButton type='submit' text='ABSENDEN' />
                </MessageSectionForm>
            </ContactContainer>
            <div className='map'>
                <div id='embedmap-canvas'>
                    <iframe frameBorder='0' src='https://www.google.com/maps/embed/v1/place?q=Friedhofsweg+16a,+36251+Ludwigsau&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8'></iframe>
                </div>
                <div id='contactData'>
                    <ContactPoint icon={faMapMarkerAlt} heading='Bio-Smart-Homes GmbH & Co. KG' subheading={'Friedhofsweg 16a \n 36251 Ludwigsau'}/>
                    <ContactPoint icon={faPhone} heading='Telefon / Mobil' subheading={phone + '\n' + mobile}/>
                    <ContactPoint icon={faEnvelope} heading='E-Mail' subheading={email}/>
                </div>
                {/* <a class="embed-maphtml" href="https://www.embed-map.com" id="injectmap-data">https://www.embed-map.com</a> */}
            </div>
        </div>
    )
}


const ContactContainer = styled.div`
    padding: 20px 20px 100px 20px;
`
const MessageSectionForm = styled.form`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    width: 50%;
    margin: auto;

    ${StyledTextField} {
        width: 49%;
        box-sizing: border-box;

        @media(max-width: 600px) {
            width: 100%;
            box-sizing: border-box;
        }
    }

    @media(max-width: 900px) {
        width: 75%;
    }

    @media(max-width: 600px) {
        width: 90%;
    }
`
