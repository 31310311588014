import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { colors } from "../style"

export const ListWrapper = () => {

}

export const ListItem = () => {
    return (
        <ItemContainer>
            <IconContainer className="iconContainer">
                <StyledIcon icon={faCheck} />
            </IconContainer>
            <Text>
                <Heading>New System Installations</Heading>
                <Description>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer eget massa </Description>
            </Text>
        </ItemContainer>
    )
}

const ItemContainer = styled.div`
    display: flex;
    width: 30%;
    font-family: 'Lato', sans-serif;
`

const Text = styled.div`
    padding-left: 15px;
`

const Heading = styled.p`
    margin-top: 6px;
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: bold;
`

const Description = styled.div`
    font-size: 13px;
    color: ${colors.gray};
    line-height: 20px;
`

const IconContainer = styled.div`
    color: white;
`

const StyledIcon = styled(FontAwesomeIcon)`

`