import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { CardWrapper } from "../components/Card";
import { colors, StyledH1, StyledH2, StyledH3 } from "../style";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const VorteilBox = (props) => {
    return (
        <StyledVorteilBox>
            <FontAwesomeIcon icon={faCheck} color={colors.yellow} size='5px' />
            <div>{props.children}</div>
        </StyledVorteilBox>
    )
}

export default function SmartHome() {
    return(
        <>
            <StyledH1>Das intelligente Haus</StyledH1>
            <CardWrapper>
                <StyledH3>Ein intelligentes Gebäude denkt mit und passt sich an. Die Vorteile davon liegen auf der Hand:</StyledH3>
            </CardWrapper>
            <Wrapper>
                <VorteilBox>Zeitersparnis durch Automatisierung</VorteilBox>
                <VorteilBox>Mehr Sicherheit</VorteilBox>
                <VorteilBox>Anpassbar auf verschiedene Anwendungsfälle</VorteilBox>
                <VorteilBox>Heiz bzw. Kühlkosten sparen</VorteilBox>
                <VorteilBox>Einfach zu bedienen</VorteilBox>
            </Wrapper>
        </>
    )
}

const Wrapper = styled.div`
    padding: 0px 15% 20px 15%;
`

const StyledVorteilBox = styled.div`
    padding: 5px 12px;
    margin: 8px;
    display: flex;
    align-items: center;
    //border: 3px solid ${colors.gray};
    border-radius: 100px;

    & > svg {
        margin-right: 10px;
    }

    & > div {
        font-weight: 500;
    }

    & > span {
        width: 12px;
        height: 12px;
        border-radius: 100px;
        padding: 5px;
        & > svg {
            height: 14px;
        }
    }
`