import IntroImage3_mini from '../img/IntroImage3_mini.jpg'
import pvHaus from '../img/PV-min.jpg'
import bshLogo from '../Logo_BSH-01.png'
import styled from 'styled-components'
import { OutlinedButton, YellowButton } from '../components/Buttons'
import { colors, StyledH1, StyledH2, StyledH3 } from '../style'
import { Ratings } from '../components/Ratings'
import { Banner } from '../components/CTABanner'
import { useMobile } from '../customhooks/customhooks'
import { Card, CardWrapper, FlipCard, VerticalCard } from '../components/Card'
import { values } from '../data'
import { Link } from 'react-router-dom'
import NewSlider from '../components/NewSlider'
import ScrollIcon from '../components/ScrollIcon'
import {Helmet} from "react-helmet";

export const Intro = () => {
    const { isMobile } = useMobile()

    return (
        <div style={{width: '100%'}}>
            <Helmet>
                <meta name="description" content="BSH ELEKTRO – Ihr Partner für Elektroinstallation, Smart-Home und Solaranlagen!" />
                <title>BSH ELEKTRO – Bio-Smart-Homes GmbH & Co. KG</title>
                <link rel="canonical" href="https://www.bsh-elektro.de" />
            </Helmet>
            <IntroWrapper>
                <BackgroundImage src={IntroImage3_mini} alt='Smart Home'/>
                <div>
                    <BshLogo isMobile={isMobile} src={bshLogo} alt='Logo der Bio-Smart-Homes GmbH & Co. KG'/>
                    <Subline>Ingenieurbetrieb für elektrotechnische Planung und Installation</Subline>
                    <ScrollIcon />
                </div>
            </IntroWrapper>
            <PvSection>
                <img width={'100%'} src={pvHaus} alt='Modernes Energiesparhaus mit Solarmodulen' />
                <div>
                    <div><h4>Eigener Strom vom Dach JETZT noch günstiger, dank dem Entfall der Mehrwertsteuer auf Solartechnik in 2023!</h4></div> <br/>
                    <div style={{marginBottom: '20px'}}>Sie möchten sich unabhängig von steigenden Energiepreisen machen? Dann ist eine Solaranlage von BSH ELEKTRO auf Ihrem eigenen Dach genau die richtige Lösung!</div>
                    {/* <span>Wir nehmen Ihre Anforderungen auf und erstellen Ihnen ein Komplettangebot. Von Planung, Anlieferung, Installation, Anmeldung beim Netzbetreiber bis zur Inbetriebnahme einer Wallbox oder der Anbindung Ihrer Wärmepumpe sind wir Ihr kompetenter Ansprechpartner.</span> */}
                    {/* <Link to='/leistungen/solar'>
                        <YellowButton text='Jetzt informieren!'/>
                    </Link> */}
                    <Link to='/kontakt'>
                        <YellowButton text='Jetzt kontaktieren!'/>
                    </Link>
                </div>
            </PvSection>
            <div style={{padding: '50px 0'}}>
                <StyledH2>Unsere Werte </StyledH2>
                {!isMobile 
                    ? <><CardWrapper>
                        {values.map(value => {
                            return <FlipCard icon={value.icon} heading={value.heading} text={value.text} />
                        })}
                        </CardWrapper>
                    </>
                    : <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        {values.map(value => {
                            return <VerticalCard icon={value.icon} heading={value.heading} text={''} />
                        })}
                    </div>
                }
                <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: '20px', marginBottom: '20px'}}>
                    <Link to='ueberUns'>
                        <OutlinedButton text='Mehr über uns'/>
                    </Link>
                </div>
            </div>
            <RatingsSection>
                <StyledH2>Das sagen Kunden über uns </StyledH2>
                <NewSlider />
            </RatingsSection>
            <Banner
                text='Zu unseren Leistungen'
                button='Unsere Leistungen'
                link='/leistungen'
            />
        </div>
    )
}

const Wrapper = styled.div`
    height: calc(100vh - 89px);
    overflow: hidden;
    position: relative;

    cursor: default;
`

const PvSection = styled.div`
    padding: 70px 10%;
    background: ${colors.lightgray};
    color: ${colors.black};

    display: grid;
    column-gap: 5%;
    grid-template-columns: 35% auto;
    align-items: center;

    & div:first-child {
        border-left: 3px solid ${colors.yellow};
        padding-left: 8px;
        color: ${colors.darkgray};
        font-weight: 500;
        text-shadow: 2px 2px 4px #3E3E3E20;
        font-size: 22px;

        h4 {
            margin: 0;
            font-weight: 500;
        }
    }

    & img {
        box-shadow: 4px 4px 4px ${colors.gray};
        border-radius: 3px;
    }

    @media (max-width: 992px) {
        grid-template-columns: auto auto;
        row-gap: 15px;
    }
    @media (max-width: 600px) {
        grid-template-columns: auto;
        row-gap: 20px;
    }
`


const RatingsSection = styled.div`
    background: ${colors.lightgray};
    padding: 50px 0;
`

const IntroWrapper = styled.div`
    background-color: black;
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 85px);
    position: relative;
    text-shadow: 1px 1px 5px rgb(0 0 0 / 20%);
    overflow: hidden;
    padding: 0 20px 0 20px;

    & > div {
        height: inherit;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }
`

const BackgroundImage = styled.img`
    @keyframes imageIntro {
        from {opacity: 0.1;}
        to {opacity: 1;}
    }

    animation-name: imageIntro;
    animation-duration: 1.5s;

    width: 101%;
    height: 105%;
    object-fit: cover;
    position: absolute;
    z-index: -1;
    filter: blur(3px) brightness(0.5);
`  

const BshLogo = styled.img`
    @keyframes logoIntro {
        0% {
            opacity: 0.8;
            transform: scale(0.6);
        }
        100% {
            transform: scale(1);
        }
    }

    animation-name: logoIntro;
    animation-duration: .4s;
    width: ${props => props.isMobile ? '90%' : '50%'};
`

const Subline = styled.h3`
    @keyframes sublineIntro {
        0% {
            opacity: 0;
            transform: translateY(60px);
        }
        30% {
            opacity: 0;
        }
        100% {
            transform: scale(1);
        }
    }

    @media (min-width: 1800px) {
        font-size: 32px;
    };

    animation-name: sublineIntro;
    animation-duration: 2.5s;

    color: ${colors.lightgray};
    margin: 5px;
    font-size: 25px;
    text-align: center;
`