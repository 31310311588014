import { Link } from "react-router-dom"
import styled from "styled-components"
import { colors } from "../style"
import { WhiteButton } from "./Buttons"

export const Banner = ({text, button, link}) => {
    return (
        <Wrapper>
            <StyledText>{text}</StyledText>
            <Link to={link}>
                <WhiteButton text={button} />
            </Link>
            
        </Wrapper>
    )
}

const Wrapper = styled.div`
    background-color: ${colors.yellow};
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 30px;
    row-gap: 25px;
    padding: 60px 20px;
    color: black;
`

const StyledText = styled.div`
    color: white;
    font-size: 30px;
    font-weight: 500;
    text-align: center;
`