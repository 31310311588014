import styled from "styled-components"
import { colors, StyledH2 } from "../style"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useMobile } from "../customhooks/customhooks"

export const Grid = props => {
    return (
        <Wrapper reverse={props.reverse} id={props.id}>
            {props.children}
        </Wrapper>  
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: ${props => props.reverse ? 'row-reverse' : 'row'};
    scroll-margin-top: 71px;
`

export const TextColumn = ({ icon, heading, subheading, text, bgColor }) => {
    const { isMobile } = useMobile()

    return (
        <div style={{flex: '1 1 450px', padding: '10vh 6%', background: bgColor, boxSizing: 'border-box'}}>
            <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: isMobile ? '15px' : '30px'}}>
                <div style={{width: isMobile ? '15%' : '22%'}}>
                    <IconContainer>
                        <FontAwesomeIcon size={isMobile ? '1x' : '2x'} color='black' icon={icon}/>
                    </IconContainer>
                </div>
                <div style={{width: isMobile ? '85%' : '78%'}}>
                    <StyledH2 style={{marginTop: '0', textAlign: 'left', marginBottom: '12px'}}>{heading}</StyledH2>
                    <div style={{fontWeight: '500', fontSize: '20px'}}>{subheading}</div>
                </div>
            </div>
            <div style={{fontSize: '18px', lineHeight: '22px', fontWeight: '300'}}>
                {text}
            </div>
        </div>
    )
}

const IconContainer = styled.div`
    width: 75px;
    height: 75px;
    background: ${colors.yellow};
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 600px) {
        width: 40px;
        height: 40px;
    }
`

export const ImageColumn = ({ src, alt }) => {
    return (
        <div style={{flex: '1 1 350px'}}>
            <StyledImage alt={alt} src={src} />
        </div>
    )
}

const StyledImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`