import styled from "styled-components"
import { colors } from "../style"

const base = '0.6rem'

const ScrollIcon = () => {
    return <Wrapper>
        <div class="container">
            <div class="chevron"></div>
            <div class="chevron"></div>
            <div class="chevron"></div>
        </div>
    </Wrapper>
}

const Wrapper = styled.div`
    animation-name: section-appear;
    animation-duration: 4s;

    @keyframes section-appear {
        0% {
            opacity: 0;
        }
        60% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    .container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .chevron {
        position: absolute;
        bottom: 70px;
        width: 25px;
        height: 4px;
        opacity: 0;
        transform: scale(0.3);
        animation: move-chevron 3s ease-out infinite;
    }

    .chevron:first-child {
        animation: move-chevron 3s ease-out 1s infinite;
    }

    .chevron:nth-child(2) {
        animation: move-chevron 3s ease-out 2s infinite;
    }

    .chevron:before, .chevron:after {
        content: '';
        position: absolute;
        top: 0;
        height: 100%;
        width: 50%;
        background: ${colors.yellow};
    }

    .chevron:before {
        left: 0;
        transform: skewY(30deg);
    }

    .chevron:after {
        right: 0;
        width: 50%;
        transform: skewY(-30deg);
    }

    @keyframes move-chevron {
        25% {
            opacity: 1;
        }
        33.3% {
            opacity: 1;
            transform: translateY(1px);
        }
        66.6% {
            opacity: 1;
            transform: translateY(30px);
        }
        100% {
            opacity: 0;
            transform: translateY(50px) scale(0.5);
        }
    }
`

export default ScrollIcon