import styled from "styled-components"
import { colors } from "../style"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export const YellowButton = ({text}) => {
    return (
        <StyledYellowButton>
            {text}
        </StyledYellowButton>
    )
}

export const OutlinedButton = ({text}) => {
    return (
        <StyledOutlinedButton>
            {text}
        </StyledOutlinedButton>
    )
}

export const OutlinedButtonBlack = ({text}) => {
    return (
        <StyledOutlinedButtonBlack>
            {text}
        </StyledOutlinedButtonBlack>
    )
}

export const WhiteButton = ({text}) => {
    return (
        <StyledWhiteButton>
            {text}
        </StyledWhiteButton>
    )
}

export const CircleButton = ({ icon, text, link }) => {
    return (
        <a href={'#'+link} style={{textDecoration: 'none'}}>
            <StyledCircleButton className="circleButton">
                <CircleButtonIconContainer>
                    <CircleButtonIcon icon={icon} />
                </CircleButtonIconContainer>
                <CircleButtonText>
                    {text}
                </CircleButtonText>
            </StyledCircleButton>   
        </a>
    )
}


const StyledButton = styled.button`
    font: 15px 'Montserrat', sans-serif;
    font-weight: 500;
    width: 200px;
    height: 45px;
    cursor: pointer;
`

const StyledYellowButton = styled(StyledButton) `
    border: 2px solid ${colors.yellow};
    background-color: ${colors.yellow};
    color: ${colors.white};
    margin: auto;

    transition: all 0.2s;

    &:hover {
        border: 2px solid ${colors.black};
        background-color: ${colors.black};
    }
`

const StyledOutlinedButton = styled(StyledButton) `
    border: 2px solid ${colors.yellow};
    background-color: ${colors.white};
    color: ${colors.black};

    transition: all 0.2s;

    &:hover {
        background-color: ${colors.yellow};
        color: ${colors.white};
    }
`

const StyledOutlinedButtonBlack = styled(StyledButton) `
    border: 2px solid ${colors.black};
    background-color: ${colors.yellow};
    color: ${colors.black};

    transition: all 0.2s;

    &:hover {
        background-color: ${colors.black};
        color: ${colors.white};
    }
`

const StyledWhiteButton = styled(StyledButton)`
    border: 2px solid ${colors.white};
    background-color: ${colors.white};
    color: ${colors.black};

    transition: all 0.2s;

    &:hover {
        background-color: ${colors.black};
        border: 2px solid ${colors.black};
        color: ${colors.white};
    }
`

const StyledCircleButton = styled.div`
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 200px;
    margin: 15px;
    padding: 5px;

    transition: all .2s;

    &:hover {
        transform: scale(1.04);
        cursor: pointer;
    }
`
const CircleButtonIconContainer = styled.div`
    width: 90px;
    height: 90px;
    background-color: ${colors.yellow};
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;

    /* &:hover {
        background-color: ${colors.darkgray}
    } */
`
const CircleButtonIcon = styled(FontAwesomeIcon)`
    width: 40px !important;
    height: 40px !important;
    color: ${colors.lightblack};

    /* &:hover {
        color: ${colors.yellow}
    } */
`
const CircleButtonText = styled.div`
    color: ${colors.lightblack};
    text-transform: uppercase;

    transition: text-shadow .2s;

    /* &:hover {
        color: ${colors.yellow}
    } */
`