import styled from "styled-components"
import { useMobile } from "../customhooks/customhooks"
import { colors, StyledH1 } from "../style"
import { phone, email } from "../data"

export const Impress = () => {
    const { isMobile } = useMobile()

    return (
        <div>
            <StyledH1>Impressum</StyledH1>
            <Wrapper>
                <p>
                    <div><strong>Bio-Smart-Homes GmbH & Co. KG</strong></div>
                    <div>Friedhofsweg 16a</div>
                    <div>36251 Ludwigsau</div>
                    <div>Tel. {phone}</div>
                    <div>E-Mail: {email}</div>
                    <div>Registergericht: Amtsgericht Bad Hersfeld</div>
                    <div>Registernummer: HRA 1886</div>
                    <br/>
                    <div><strong>Persönlich haftende Gesellschafter der Bio-Smart-Homes GmbH & Co. KG:</strong></div>
                    <div>Bio-Smart-Homes Verwaltungs GmbH</div>
                    <div>Geschäftsführer der Bio-Smart-Homes Verwaltungs GmbH: </div>
                    <div>Johann Dick</div>
                    <br/>
                    <div>Registergericht: Amtsgericht Bad Hersfeld </div>
                    <div>Registernummer: HRB 2890</div>
                    <br/>
                    <div><strong>Umsatzsteuer-Identifikationsnummer gemäß § 27a Umsatzsteuergesetz:</strong></div>
                    <div>DE-334723090</div>
                    <br/>
                    <div><strong>Redaktionelle Verantwortung:</strong></div>
                    <div>Johann Dick</div>
                    <br/>
                    <div>
                        <div><strong>Haftungshinweis:</strong></div>
                        <div>Wir übernehmen keinerlei Verantwortung oder Haftung für die Angaben auf dieser Webseite. Unser Ziel ist es, aktuelle und genaue Informationen bereitzustellen. Allerdings kann nicht garantiert werden, dass die auf dieser Webseite verfügbaren Angaben tatsächlich aktuell, umfassend, komplett oder genau sind.</div><br/>
                        <div>Bei den bereitgestellten Informationen handelt es sich um solche allgemeiner Art, die nicht auf die besonderen Bedürfnisse bestimmter Personen oder Unternehmen abgestimmt sind. Insbesondere soll durch sie keine Beratung erfolgen.</div><br/>
                        <div>Sofern von dieser Webseite auf andere Webseiten verwiesen wird, können wir deren Inhalt nicht beeinflussen und für diesen auch keine Verantwortung übernehmen.</div><br/>
                    </div>

                </p>
            </Wrapper>
        </div>
    )
}

const Wrapper = styled.div`
    text-align: left;
    padding: 25px 10%;
    color: ${colors.lightblack};
`