import styled from "styled-components"

export const colors = {
    white: '#fff',
    yellow: '#f7b72f',
    darkYellow: '#e6a620',
    green: '#69C350',
    lightgray: '#f6f6f6',
    midgray: '#e5e5e5',
    gray: '#808083',
    darkgray: '#3e3e3d',
    lightblack: '#25262e',
    black: '#0e0d12'
}

export const StyledH3 = styled.h2`
    text-align: center;
    font-family: "Lato", sans-serif;
    font-size: 25px;
    color: ${colors.gray};
    @media (max-width: 600px) {
        font-size: 18px;
    }
    margin-left: 10%;
    margin-right: 10%;
`

export const StyledH2 = styled.h2`
    text-align: center;
    font-family: "Lato", sans-serif;
    font-size: 30px;
    color: ${colors.darkgray};
    margin: 20px 0;
    @media (max-width: 600px) {
        font-size: 24px;
    }
`

export const StyledH1 = styled.h1`
    text-align: center;
    font-family: "Lato", sans-serif;
    font-size: 34px;
    margin: 40px 0 22px 0;
    color: ${colors.darkgray};
    @media (max-width: 600px) {
        font-size: 30px;
    }
`