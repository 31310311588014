import { faRoad, faHeadSideCough, faHeadset, faComments, faHandshake } from '@fortawesome/free-solid-svg-icons'
import { useMobile } from '../customhooks/customhooks'

import Bild1 from '../img/Bild1.jpg'
import Bild2 from '../img/Bild2.JPG'

import { FlipCard, CardWrapper, Card, VerticalCard } from '../components/Card'
import { ListItem } from '../components/List'
import { colors, StyledH1, StyledH2, StyledH3 } from '../style'
import { Grid, ImageColumn, TextColumn } from '../components/Grid'
import styled from 'styled-components'
import { values } from '../data'
import { Ratings } from '../components/Ratings'
import NewSlider from '../components/NewSlider'
import {Helmet} from "react-helmet";

export default function AboutUs() {

    const { isMobile } = useMobile()

    return (
        <div className="values">
            <Helmet>
                <meta name="description" content="BSH ELEKTRO ist ein Ingenieurbetrieb für elektrotechnische Planung und Installation. 2020 gegründet, verfolgen wir das Ziel, Gebäude intelligenter zu machen, dies auch unter Berücksichtigung baubiologischer Gesichtspunkte" />
                <title>Über uns – BSH ELEKTRO</title>
                <link rel="canonical" href="https://www.bsh-elektro.de/aboutUs" />
            </Helmet>
            <StyledH1>Über uns</StyledH1>
            <StyledH3 style={{paddingBottom: '50px'}}>BSH ELEKTRO ist ein Ingenieurbetrieb für elektrotechnische Planung und Installation. 2020 gegründet, verfolgen wir das Ziel, Gebäude intelligenter zu machen und dies unter Berücksichtigung baubiologischer Gesichtspunkte.</StyledH3>
            <StyledQuoteWrapper className="aboutUs">
                <ImageWrapper>
                    <img style={{marginBottom: '-4px'}} src={Bild2} alt="" ></img>
                </ImageWrapper>
                <div id="quoteSection">
                    <p id='quote'>„Die Umsetzung von Ideen und Projekten muss nicht immer kompliziert sein. Vielmehr muss die Idee und das Konzept überzeugend sein, und dies mit Menschen, deren Philosophie der Unseren gleicht. Nur gemeinsam kann man so langfristig zum Erfolg gelangen. <br/> Dazu stehe ich"</p>
                    <p id='name'>- Johann Dick -</p>
                    <div id="nameDetail">
                        <div>Dipl.-Wirt.-Ing. (FH), M. Sc.</div>
                        <div>Gründer & Geschäftsführer von BSH Elektro</div>
                    </div>
                </div>
            </StyledQuoteWrapper>
            <div style={{padding: '50px 0', background: colors.white}}>
                <StyledH2>Unsere Werte</StyledH2>
                <CardWrapper>
                    {values.map(value => {
                        return <VerticalCard icon={value.icon} heading={value.heading} text={value.text}/>
                    })}
                </CardWrapper>
            </div>
            <div style={{padding: '50px 0', background: colors.lightgray}}>
                <StyledH2>Das sagen Kunden über uns </StyledH2>
                <NewSlider />
            </div>
        </div>
    )
}

const StyledQuoteWrapper = styled.div`
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;
    background-color: ${colors.lightgray};

    #quoteSection {
        line-height: 32px;
        font-size: 22px;
        padding: 10vh 6%;
        box-sizing: border-box;
        width: 60%;
        text-align: center;
        white-space: pre-line;
        text-shadow: 1px 1px 2px rgba(100, 100, 100, 0.2);
        color: var(--gray);
        cursor: default;

        #quote {
            margin-top: 0;
        }

        #name {
            font-family: "Bradley Hand", sans-serif;
            margin-bottom: -8px;
        }

        #nameDetail {
            font-size: 14px;
            margin-top: 5px;
            font-weight: 200;
        }

        @media (max-width: 600px) {
            width: 90%;
            font-size: 20px;
        }
    }

    /* @media (max-width: 600px) {
        margin-bottom: 30px;
    } */
`

const ImageWrapper = styled.div`
    width: 40%;
    overflow: hidden;

    & img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        filter: grayscale(1);
    }

    @media(max-width: 600px) {
        width: 100%;
    }
`

