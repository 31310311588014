import { useState, useEffect } from 'react'

const MOBILE_WIDTH = 600

export function useMobile(customWidth) {
    const width = customWidth || MOBILE_WIDTH
    const [isMobile, setIsMobile] = useState(window.innerWidth < width)

    function handleWindowSizeChange() {
        setIsMobile(window.innerWidth < width)
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange)
        return () => window.removeEventListener('resize', handleWindowSizeChange)
    }, [])

    return { isMobile }
}