import './App.css';
import { useEffect } from 'react';
import { Route, Router, Routes, useLocation,  Navigate } from 'react-router-dom'

import Contact from './pages/Contact';
import Footer from './components/Footer';
import { Intro } from './pages/Intro';
import Navbar from './components/Navbar';
import AboutUs from './pages/AboutUs';

import { Services } from './pages/Services';
import Projects from './sections/Projects';
import { Impress } from './pages/Impress';
import { PrivacyPolicy } from './pages/PrivacyPolicy';
import SmartHome from './pages/SmartHome';
import Solar from './pages/Solar';

export default function App() {

    const location = useLocation()

    useEffect(() => {
        document.documentElement.scrollTop = 0
    }, [location.pathname])

    return (
        <>
            <Navbar />
            <Routes>
                <Route path='/' element={<Intro />} />
                <Route path='/smartHome' element={<SmartHome />} />
                <Route path='/leistungen' element={<Services />} />
                <Route path='/ueberUns' element={<AboutUs />} />
                <Route path='/kontakt' element={<Contact />} />
                <Route path='/impressum' element={<Impress />} />
                <Route path='/datenschutz' element={<PrivacyPolicy />} />
                {/* <Route path='/referenzen' element={<Projects />} /> */}
                {/* <Route path='/leistungen/solar' element={<Solar />} /> */}
                <Route path="*" element={<Navigate replace to="/" />} />
            </Routes>
            <Footer />
        </>
    )
}
