import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { colors } from '../style'

export const FlipCard = ({icon, heading, text}) => {
    return (
        <StyledFlipCard className="flip-card">
            <FlipCardInner className="flip-card-inner">
                <FlipCardFront>
                    <StyledIcon icon={icon} size='50px' />
                    <div>{heading}</div>
                </FlipCardFront>
                <FlipCardBack>
                    <p>{text}</p>
                </FlipCardBack>
            </FlipCardInner>
        </StyledFlipCard>
    )
}

export const Card = ({icon, heading, text}) => {
    return (
        <StyledCard>
            <div style={{marginBottom: '5px', display: 'flex', alignItems: 'center'}}>
                <StyledIcon icon={icon} size='35px' style={{marginRight: '10px'}} />
                <span><strong>{heading}</strong></span>
            </div>
            <div>
                <div style={{marginBottom: '2px'}}>{text}</div>
            </div>
        </StyledCard>
    )
}

export const VerticalCard = ({icon, heading, text}) => {
    return (
        <StyledVerticalCard>
            <StyledIcon icon={icon} size='50px'/>
            <span><strong>{heading}</strong></span>
            <div>{text}</div>
        </StyledVerticalCard>
    )
}

export const CardWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
`

const StyledCard = styled.div`
    margin: 30px 15px; 
    border: 2px solid var(--midgray);
    padding: 14px 10px;
    border-radius: 4px;
`

const StyledIcon = styled(FontAwesomeIcon)`
    width: ${props => props.size};
    height: ${props => props.size};
    color: ${colors.yellow};
`

const StyledFlipCard = styled.div`
    width: 200px;
    height: 220px;
    perspective: 1000px;
    margin: 20px;

    &:hover FlipCardInner{
    }
`

const FlipCardInner = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: all 0.6s;
    transform-style: preserve-3d;
    border: 2px solid var(--midgray);
    font-size: 17px;
    cursor: default;

    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.05), -5px -5px 20px rgba(0, 0, 0, 0.05);

    &:hover {
        border: 2px solid ${colors.yellow};
        transform: rotateY(180deg) scale(1.1);
        box-shadow: 5px 5px 20px rgba(247, 183, 47, 0.1), -5px -5px 20px rgba(247, 183, 47, 0.1);
    }     
`

const FlipCardSides = styled.div`
    position: absolute;
    padding: 15px 12px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background-color: white;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
`

const FlipCardFront = styled(FlipCardSides)`
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    font-weight: bold;
`

const FlipCardBack = styled(FlipCardSides)`
    color: ${colors.gray};
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotateY(180deg);
`

const StyledVerticalCard = styled(StyledCard)`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 200px;
    text-align: center;
    row-gap: 15px;
    border: none;

    @media(max-width: 600px) {
        margin: 20px;

        & ${StyledIcon} {
            padding: 15px;
        }
    }

    & ${StyledIcon} {
        background: ${colors.darkgray};
        padding: 20px;
        border-radius: 50%;
    }
`