import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBolt, faThumbsUp, faUser } from '@fortawesome/free-solid-svg-icons'
import styled from "styled-components"
import { colors, StyledH2 } from "../style"

const ratings = [
    {
        text: 'Absolut zuverlässig und kompetent. Hab gleich noch ein paar Änderungen am Sicherungskasten machen lassen. Fairer Preis. Immer wieder gerne ????????',
        author: 'Ralf M. im November 2021'
    },
    {
        text: 'Kompetente, zuverlässige und zügige Erledigung der Arbeiten. Problemlose Termin-Abstimmungen.',
        author: 'Jürgen S. im September 2021'
    },
    {
        text: 'Alles super, sehr zuverlässig und sehr sauber gearbeitet. Gerne jederzeit wieder! ',
        author: 'Monika A. im Juli 2021'
    },
    {
        text: 'Die Kommunikation klappte hervorragend. Das Angebot sehr günstig, die Dokumentation übersichtlich und nachvollziehbar. Die Durchführung der Arbeiten erfolgte sehr sachkundig und kompetent, bei der Schlußabnahme ergaben sich keine fachlichen Mängel. Herr Dick war stets erreichbar und freundlich. Es war bestimmte nicht mein letzte Auftrag an die Firma, ich kann sie uneingeschränkt empfehlen.',
        author: 'Andreas T. im Juni 2021'
    },
    {
        text: 'Herr Dick war immer schnell und gut erreichbar. Daher klappte die Abstimmung sehr gut. Das Angebot war i.O. und günstig, die Lieferzeit super. So konnte das Projekt kurzfristig umgesetzt werden. Alle Arbeiten wurden gut umgesetzt, so dass wir keine Beanstandungen hatten und ihn uneingeschränkt weiter empfehlen können.',
        author: 'Holger K. im Juli 2021'
    },
]

export const Ratings = () => {
    return (
        <div>
            <StyledH2>Das sagen unsere Kunden:</StyledH2>
            <Wrapper>
                {ratings.map(rating => {
                    return <RatingCard text={rating.text} author={rating.author}/>
                })}
            </Wrapper>
        </div>
    )
}

const Rating = () => {
    return (
        <div>

        </div>
    )
}

const RatingCard = ({text, author}) => {
    return (
        <StyledRatingCard>
            <div style={{color: colors.yellow, fontSize: '14px', marginBottom: '5px'}}>
                <FontAwesomeIcon icon={faBolt}/><FontAwesomeIcon icon={faBolt}/><FontAwesomeIcon icon={faBolt}/><FontAwesomeIcon icon={faBolt}/><FontAwesomeIcon icon={faBolt}/>
            </div>
            <Testimonial>{text}</Testimonial>
            <Author>
                {}
                <FontAwesomeIcon icon={faUser}/>
                <span style={{paddingLeft: '7px'}}>{author}</span>
            </Author>
        </StyledRatingCard>
    )
}

const Wrapper = styled.div`
    /* display: flex;
    flex-wrap: wrap;
    justify-content: center; */
    margin-bottom: 25px;
    font-family: "Lato", sans-serif;
`

const StyledRatingCard = styled.div`
    width: 250px;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1), -2px -2px 6px rgba(0, 0, 0, 0.05);
    padding: 14px;
    border-radius: 4px;
    margin: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: white;
    display: inline-block;
`

const Testimonial = styled.div`
    margin: 10px 0 14px 0;
`

const Author = styled.div`
    font-size: 14px;
    color: ${colors.gray};
`