import styled from "styled-components"
import { CircleButton } from "../components/Buttons"
import { faBolt, faCheck, faLightbulb, faNetworkWired, faSatellite, faIndustry, faLeaf, faHouseSignal, faCircleNodes, faSolarPanel } from "@fortawesome/free-solid-svg-icons"
import { Grid, TextColumn, ImageColumn} from "../components/Grid"
import { StyledH1 } from "../style"
import {Helmet} from "react-helmet";

import lowEMF from '../img/services/lowEMF.jpeg'
import industry from '../img/services/industry.jpeg'
import communication from '../img/services/communication.jpeg'
import electric from '../img/services/electric.jpeg'
import lighting from '../img/services/lighting.jpeg'
import smarthome from '../img/services/smarthome.jpeg'
import pv from '../img/services/pv.jpeg'
import { Banner } from "../components/CTABanner"

const services = [
    {
        title: 'Photovoltaik',
        icon: faSolarPanel,
        image: pv,
        alt: 'Photovoltaikanlage auf Freifläche',
        text: <div>Mit einer Solaranlage von BSH ELEKTRO machen Sie sich unabhängig uns sparen dabei auch noch Geld.
        <br/><br/>
        Wir nehmen Ihre Anforderungen auf und erstellen Ihnen ein Komplettangebot. Von Planung, Anlieferung, Installation, Anmeldung beim Netzbetreiber bis zur Inbetriebnahme einer Wallbox oder der Anbindung Ihrer Wärmepumpe sind wir Ihr kompetenter Ansprechpartner.
        <br/><br/>
        Durch intelligentes Lastmanagement, das meint z.B. die Nutzung von überschüssigem Strom Ihrer PV-Anlage für eine Wärmepumpe, können Sie Ihre Stromkosten drastisch senken und den Amortisationszeitraum Ihrer Anlage verkürzen.
        </div>,
    },
    {
        title: 'Elektroinstallation',
        icon: faBolt,
        image: electric,
        alt: 'Elektriker bei Schaltschrankarbeiten',
        text: <div>Wir sind ihr Dienstleister für Elektroinstallationen. Unser Angebot deckt die ganze Bandbreite von Elektroinstallationen ab. Ob Starkstrom oder Schwachstrom – wir sind von der Planung bis hin zur Installation kompetent an Ihrer Seite. <br/><br/> Neben den klassischen Verkabelungen kümmern sich unsere Fachleute auch um die Technik, die an unseren Anschlüssen hängt: Internet, Telefon, Musik- oder Multimediaanlage, Geräte in Küche und Waschraum sowie Kamera- und Sicherheitstechnik. <br/><br/> Egal ob für private Wohnbauten, landwirtschaftliche Betriebe, Industrie oder Gewerbe - wir finden eine effiziente Lösung für Sie und begleiten Sie von der Beratung, über die Installation bis hin zum Service.</div>,
    },
    {
        title: 'Kommunikationstechnik, Netzwerke und IT',
        icon: faCircleNodes,
        image: communication,
        alt: 'Netzwerkschrank mit Switch und LAN-Kabeln',
        text: <div>Mit modernsten Kommunikationsanlagen erleichtern wir unser tägliches Leben und schaffen neue, ungeahnte Möglichkeiten in unserem Arbeitsbereich. Doch wenn wir für unseren Haushalt entscheiden müssen, welche Technik wir für die Kommunikation auswählen sollen, kommen wir an Grenzen. Wann beginnen echte Erfahrungen und wo hören sie in unserer digitalen Welt auf? Das Team BSH ELEKTRO berät Sie dabei, für ein optimales Konzept und eine leistungsfähige Vernetzung – am besten verkabelt. <br/><br/> Konventionelle Kupferverkabelung oder zukunftsweisende Glasfaser? Effizientes Informations- und oder überholtes Telekommunikationsnetz? Wir von BSH ELEKTRO überprüfen den Zustand Ihrer Verkabelung bezüglich Ihrer Kommunikationszwecke und bieten Ihnen eine sorgfältige Planung, konsequente Realisierung und Kontrolle der installierten Netzwerktopologie an.</div>,
    },
    {
        title: 'Beleuchtung und Beratung',
        icon: faLightbulb,
        image: lighting,
        alt: 'Wohnzimmer mit intelligenter LED-Beleuchtung',
        text: <div>Vernetzung durch intelligente Beleuchtungsobjekte und emotionale Lichtkonzepte. Der Trend steht fest: Die Verwendung einer künstlichen Lichtquelle hat sich zur aktuellsten Technologie entwickelt und ist eher ein umfangreiches intelligentes System als lediglich die Betätigung eines Schalters. Das ganzheitliche Licht-Management-Konzept durch Steuerungskomponenten oder Präsenz- und Bewegungsmelder bedient zudem die Themengebiete Umweltschutz, Sicherheit und Kosteneffizienz. <br/><br/> Hinzu kommt aber noch eine weitere, wichtige Aufgabe der Beleuchtung: Sie soll Wohlfühlatmosphäre kreieren und aus dem Zuhause eine Ruhe- und Rückzugsoase schaffen. Szenerie, Stimmung und Komfort im eigenen Heim sind entscheidend für das menschliche Harmonieempfinden. Die Human Centric Lighting Technologie (HCL) nimmt Einfluss auf die biologischen, visuellen und emotionalen Wirkungen von Licht auf Körper, Seele und Geist. HCL unterstützt langfristig das Wohlbefinden, die Gesundheit und die Leistungsfähigkeit des Menschen.</div>,
    },
    {
        title: 'Baubiologische Elektroinstallation',
        icon: faLeaf,
        image: lowEMF,
        alt: 'Elektrostatische Entladung',
        text: <div>Wer ein Haus baut oder eine Wohnung saniert, steht vor der Frage, auf welche Weise er seine elektrischen Installationen anbringen möchte. In Zeiten eines wachsenden Gesundheitsbewusstseins steigt die Nachfrage nach baubiologisch unbedenklichen Baumaterialien. Das große Stichwort Elektrosmog steht im Raum. Aber was ist Elektrosmog? <br/><br/> Unter Elektrosmog versteht man die Belastungen, die von elektrischen Installationen ausgehen. Tatsächlich wird mittlerweile kaum mehr bestritten, dass Elektrosmog unser Befinden beeinflusst und sogar krank machen kann.<br/><br/> Die Baubiologie kennt als gutes Gegenmittel sogenannte geschirmte Installationen. Das beginnt bei geschirmten Leitungen und Steckdosen und reicht bis hin zu strahlungsreduzierten Elektrogeräten, welche die elektrischen Felder minimieren oder komplett abschalten. <br/><br/> Der größte Vorteil einer komplett abgeschirmten Installation besteht im erhöhten Erholungswert beim Schlafen. Der Körper kann dabei tatsächlich zur Ruhe kommen. Der wegfallende Elektrosmog im Schlafbereich kann bereits dazu beitragen, dass die Regeneration und der Wiederaufbau des Körpers besser funktionieren. Sie haben schon eine bestehende, nicht abgeschirmte Installation oder planen einen Neubau? Gerne Beraten wir Sie zum Thema, Strahlungsminimierung und Schutz vor E-Smog. Sie wollen sich schützen? <br/> Wir erstellen ein Gesamtkonzept: Messungen, Beratung & Planung, Abschirmung</div>,
    },
    {
        title: 'Gebäudesystemtechnik / Smart Home',
        icon: faHouseSignal,
        image: smarthome,
        alt: 'An der Wand befestigtes iPad zur Steuerung eines Smart Homes',
        text: <div>Intelligente Hausvernetzung: Ihre speziell angepasste Lösung. <br/><br/> Ein intelligentes Zuhause verändert Ihren Alltag beträchtlich. Unser Team der BSH ELEKTRO erstellt individuelle Lösungen für die Vernetzung Ihres Wohnraumes und bietet Ihnen damit maximale Sicherheit, hohen Komfort und enorme Preisvorteile. <br/><br/> Ein Smart Home denkt mit und weiß welche Stimmung Sie morgens oder abends, beim Kochen, Spielen oder Relaxen bevorzugen. Es merkt, wenn es dunkel wird und Sie Licht brauchen. Wenn es im Haus zu warm wird, fährt das Smart Home die Beschattung runter oder stellt die Heizung an, wenn die Temperatur sinkt. Es weiß, welche Musik Sie gerne hören und schützt Ihr Haus vor Eindringlingen. Gerne zeigen wir Ihnen die Möglichkeiten in einem Real Smart Home.</div>,
    },
    {
        title: 'Industrie und Gewerbe',
        icon: faIndustry,
        image: industry,
        alt: 'Industriehalle',
        text: <div>Jetzt optimieren: Maximale Produktivität, geringer Energieaufwand. <br/><br/> Die Antwort auf die Frage nach höchster Produktion bei kleinstem Energieaufwand liegt in unserem Komplettangebot. Erkundigen Sie sich bei unseren Experten von BSH ELEKTRO, damit wir Sie zu den Themen Einsparpotenzial und Verbrauch in der Industrieelektrik und Elektronik optimal beraten können. <br/><br/> Schaltanlagenbau, Inbetriebnahme und Wartung von elektrischen Anlagen, Messungen nach DIN VDE, E-Check, etc.</div>,
    },
]

export const Services = () => {
    return (
        <>
            <Helmet>
                <meta name="description" content="Bei allen Anliegen im Bereich Solaranlagen, Wallboxen, Smart Home und sonstigen elektrotechnischen Installationen ist BSH ELEKTRO Ihr kompetenter Ansprechpartner" />
                <title>Leistungen – BSH ELEKTRO</title>
                <link rel="canonical" href="https://www.bsh-elektro.de/leistungen" />
            </Helmet>
            <StyledH1>Leistungen</StyledH1>
            <Wrapper>
                {services.map(service => {
                    return <CircleButton
                        link={service.title}
                        icon={service.icon}
                        text={service.title}
                    />
                })}
            </Wrapper>
            {services.map((service, index) => {
                const isEven = index % 2 == 0
                return <Grid reverse={isEven} id={service.title}>
                    <TextColumn
                        icon={service.icon}
                        bgColor={isEven ? '#eee' : '#fff'}
                        heading={service.title}
                        subheading=''
                        text={service.text}
                    />
                    <ImageColumn alt={service.alt} src={service.image} />
                </Grid>
            })}
            <Banner
                text='Jetzt anfragen!'
                button='Kontakt'
                link='/kontakt'
            />
        </>
    )
}

const Wrapper = styled.div`
    display: grid;
    row-gap: 40px;
    grid-template-columns: auto auto auto;
    justify-content: space-evenly;
    justify-items: center;
    padding: 40px 0;

    /* justify-content: space-evenly;
    justify-items: center;
    align-content: space-evenly;
    align-items: center; */

    @media (max-width: 992px) {
        grid-template-columns: auto auto;
        row-gap: 15px;
    }
    @media (max-width: 600px) {
        grid-template-columns: auto;
        row-gap: 15px;
    }
`