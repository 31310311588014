import styled from 'styled-components'
import { NavLink } from 'react-router-dom';

import bshLogo from '../bsh_logo_400px.png'
import { colors } from '../style';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {} from '@fortawesome/fontawesome-svg-core'
import { faBars, faBurger, faXmark, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { useMobile } from '../customhooks/customhooks';

const navOptions = [
    {name: 'START', path: ''},
    // {name: 'SMART HOME', path: 'smartHome'},
    {name: 'LEISTUNGEN', path: 'leistungen'},
    {name: 'ÜBER UNS', path: 'ueberUns'},
    {name: 'KONTAKT', path: 'kontakt'},
]

const NavItems = () => {
    return (
        <StyledNavItems>
            {navOptions.map(option => <li>
                    <StyledNavLink to={option.path}>{option.name}</StyledNavLink>
                </li>
            )}
        </StyledNavItems>
    )
}

const MobileNavItems = ({visible, clickHandler}) => {
    return (
        <StyledMobileNavItems visible={visible}>
            {navOptions.map(option => <li>
                    <StyledNavLink onClick={clickHandler} to={option.path}>{option.name}</StyledNavLink>
                </li>
            )}
        </StyledMobileNavItems>
    )
}

export default function Navbar() {

    const { isMobile } = useMobile(900)
    const [open, setOpen] = useState(false)

    const handleItemClick = () => {
        setOpen(!open)
    }

    return (
        <>{!isMobile 
        ? <StyledNavbar className="navbar">
            <StyledNavLink to=''><img src={bshLogo} alt="Firmenlogo der Bio-Smart-Homes GmbH & Co. KG"></img></StyledNavLink>
            <NavItems />
            <div id="phoneNumber" style={{fontSize: '16px'}}>
                <FontAwesomeIcon icon={faEnvelope} style={{color: colors.yellow}}/>
                <span><a id='clickMail' href='mailto:info@bsh-elektro.de'> info@bsh-elektro.de</a></span>
            </div>
        </StyledNavbar>
        : <MobileNavbar>
            {/* <input type='checkbox' id='burgerMenue' style={{display: 'none'}} onChange={(e) => console.log(e.target.checked)}/>
            <label for='burgerMenue'></label> */}
            <div style={{display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center'}}>
                <span style={{width: '50px'}}><FontAwesomeIcon icon={open ? faXmark : faBars} onClick={() => setOpen(!open)}/></span>
                <StyledNavLink to=''><img src={bshLogo} alt="Firmenlogo der Bio-Smart-Homes GmbH & Co. KG"></img></StyledNavLink>
                <span style={{width: '50px'}}></span>
            </div>
            <MobileNavItems clickHandler={handleItemClick} visible={open}/>
        </MobileNavbar>}
        </>
    )
}

const StyledNavbar = styled.div`
    width: 100%;
    background-color: #ffffff;
    position: absolute;
    padding: 10px 32px;
    box-sizing: border-box;
    color: ${colors.lightblack};

    display: flex;
    align-items: center;
    justify-content: space-evenly;

    top: 0px;
    position: sticky;
    z-index: 100;

    box-shadow: 0px 2px 8px rgba(0,0,0,0.2);

    & div {
        font-family: "Lato",sans-serif;
        font-weight: bold;
        font-size: 19px;
    }

    & img {
        width: 120px;
        display: flex;
    }

    #clickMail {
        text-decoration: none;
        color: ${colors.lightblack};
    }
`

const StyledNavItems = styled.ul`
    margin: 0px;
    display: flex;
    white-space: nowrap;
    padding-left: 0;
    content: none;

    & li {
        font-family: "Montserrat",sans-serif;
        font-weight: 500;
        font-size: 16px;
        list-style: none;
        margin: 0;
    }
    .active {
        color: ${colors.yellow};
        font-weight: 600;
    }
`

const StyledNavLink = styled(NavLink)`
    text-decoration: none;
    padding: 4px 12px;
    color: #333;
    transition: all 0.2s;

    &:hover {
        color: ${colors.yellow};
    }
`

const MobileNavbar = styled(StyledNavbar)`
    flex-direction: column;

    & img {
        width: 120px;
        display: flex;
    }
`

const StyledMobileNavItems = styled(StyledNavItems)`
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    transition: display 1s linear;
    //visibility: ${props => props.visible ? 'visible' : 'hidden'};
    display: ${props => props.visible ? 'flex' : 'none'};

    & li {
        margin: 12px 0;
    }
`