import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { colors } from '../style'
import bshLogo from '../Logo_BSH-01.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faMobile, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { email, loxonePartnerWebsite, mobile, phone } from '../data'

import loxonePartner from '../img/loxoneSilverPartner.png'

export default function Footer() {
    return (
        <Wrapper>
            <Container>
                <ContactData>
                    <Column>
                        <BshLogo src={bshLogo} alt='BSH-Logo' />
                        <Subtitle style={{paddingRight: '25px', lineHeight: '18px', marginTop: '5px', marginBottom: '30px', fontWeight:'bold'}}>Ingenieurbetrieb für elektrotechnische <br/> Planung und Installation.</Subtitle>
                        <a href={loxonePartnerWebsite} target='blank'><LoxonePartnerLogo src={loxonePartner} alt='Loxone-Partner Logo' /></a>
                    </Column>
                    <Column>
                        <Title>Adresse</Title>
                        <Subtitle>Bio-Smart-Homes GmbH & Co. KG</Subtitle>
                        <Subtitle>Friedhofsweg 16a</Subtitle>
                        <Subtitle>36251 Ludwigsau</Subtitle>
                    </Column>
                    <Column>
                        <Title>Kontakt</Title>
                        <div></div>
                        <Subtitle><StyledIcon icon={faPhone}/>{phone}</Subtitle>
                        <Subtitle><StyledIcon icon={faMobile}/>{mobile}</Subtitle>
                        <Subtitle><StyledIcon icon={faEnvelope}/>{email}</Subtitle>
                    </Column>
                    <Column>
                        <Title>Links</Title>
                        <StyledLink to='/impressum'><Subtitle>Impressum</Subtitle></StyledLink>
                        <StyledLink to='/datenschutz'><Subtitle>Datenschutz</Subtitle></StyledLink>
                        <StyledLink to='/kontakt'><Subtitle>Kontakt</Subtitle></StyledLink>
                    </Column>
                </ContactData>
                <Hr />
                <Subtitle style={{textAlign: 'center'}}>Bio-Smart-Homes GmbH & Co. KG © 2023. Alle Rechte vorbehalten</Subtitle>
            </Container>
        </Wrapper>
    )
}

const Wrapper = styled.footer`
    background-color: ${colors.black};
    padding: 50px 0 20px 0;
    font-family: 'Lato', sans-serif;
    letter-spacing: 0.5px;
`
const Container = styled.div`
    width: 90%;
    margin: auto;
`
const ContactData = styled.div`
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
`
const Title = styled.p`
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 30px;
    margin-top: 0;
    color: white;

    @media (max-width: 600px) {
        margin-bottom: 5px;
    }
`
const Subtitle = styled.p` //Uses filter for readability
    font-size: 14px;
    color: ${colors.gray}; 
    filter: brightness(1.6);
`
const Column = styled.div`
    flex: 1 1 280px;
    margin-bottom: 45px;
`
const Hr = styled.hr`
    border: 1px solid ${colors.darkgray};
    margin-top: 5px;
    margin-bottom: 38px;
`

const StyledLink = styled(Link)`
    text-decoration: none;
    &:hover ${Subtitle} {
        color: ${colors.lightgray};
    }
`

const StyledIcon = styled(FontAwesomeIcon)`
    width: 25px;
`

const BshLogo = styled.img`
    width: 80%;
`

const LoxonePartnerLogo = styled.img`
    width: 60%;

    &:hover {
        cursor: pointer;
    }
`