import { faComments, faHandshake, faRoad} from '@fortawesome/free-solid-svg-icons'

export const values = [
    {icon: faComments, heading: 'Ständiger Dialog', text: 'Nur dadurch entsteht Erneuerung und Weiterentwicklung.'},
    {icon: faHandshake, heading: 'Zuverlässigkeit und Vertrauen', text: 'Sich auf den Anderen verlassen zu können, ist die Voraussetzung einer jeden Partnerschaft.'},
    {icon: faRoad, heading: 'Geradlinigkeit', text: 'Jeder Geschäftspartner hat ein Recht darauf, dass sein Partner den Weg geht, der gemeinsam beschlossen wurde.'}
]

export const ratings = [
    {
        text: 'Super motiviertes Team. Die Arbeiten und auch Sonderwünsche wurden sofort umgesetzt. Sehr zu empfehlen!',
        author: 'Stefan H. im März 2023'
    },
    /*{
        text: 'Wir haben an unserem Haus mit verschiedenen Firmen zusammengearbeitet und bei dem Projekt (Photovoltaikanlage) mit Bio-Smart-Homes sind wir absolut begeistert. Vom Chef bis zu jedem Angestellten mit dem wir Kontakt hatten - ein junges, aufgeschlossenes und interessiertes Team. Aufgaben wurden alle in Absprache mit dem Kunden geplant und durchgeführt, auf Fragen wurde immer sympathisch und schnell reagiert. Die Arbeiten selbst wurden alle zielgerichtet erledigt und vor allem wird nie Chaos hinterlassen, im Gegensatz zu anderen Firmen. Alle arbeiten gewissenhaft. Der Chef ist erreichbar, empathisch und vor allem fachlich versiert. Egal welche Elektroarbeiten wir noch haben sollten, hier fühlen wir uns gut aufgehoben und ich bin daher zuversichtlich auch bei Bedarf in Zukunft zuerst hier anzuklopfen.',
        author: 'Jonas M. im März 2023'
    },*/
    {
        text: 'Wir haben an unserem Haus mit verschiedenen Firmen zusammengearbeitet und bei dem Projekt (Photovoltaikanlage) mit Bio-Smart-Homes sind wir absolut begeistert. Vom Chef bis zu jedem Angestellten mit dem wir Kontakt hatten - ein junges, aufgeschlossenes und interessiertes Team. Aufgaben wurden alle in Absprache mit dem Kunden geplant und durchgeführt, auf Fragen wurde immer sympathisch und schnell reagiert.',
        author: 'Jonas M. im März 2023'
    },
    {
        text: 'Absolut zuverlässig und kompetent. Hab gleich noch ein paar Änderungen am Sicherungskasten machen lassen. Fairer Preis. Immer wieder gerne.',
        author: 'Ralf M. im November 2021'
    },
    {
        text: 'Kompetente, zuverlässige und zügige Erledigung der Arbeiten. Problemlose Termin-Abstimmungen.',
        author: 'Jürgen S. im September 2021'
    },
    {
        text: 'Alles super, sehr zuverlässig und sehr sauber gearbeitet. Gerne jederzeit wieder!',
        author: 'Monika A. im Juli 2021'
    },
    {
        text: 'Die Kommunikation klappte hervorragend. Das Angebot sehr günstig, die Dokumentation übersichtlich und nachvollziehbar. Die Durchführung der Arbeiten erfolgte sehr sachkundig und kompetent, bei der Schlußabnahme ergaben sich keine fachlichen Mängel. Herr Dick war stets erreichbar und freundlich. Es war bestimmte nicht mein letzte Auftrag an die Firma, ich kann sie uneingeschränkt empfehlen.',
        author: 'Andreas T. im Juni 2021'
    },
    {
        text: 'Herr Dick war immer schnell und gut erreichbar. Daher klappte die Abstimmung sehr gut. Das Angebot war i.O. und günstig, die Lieferzeit super. So konnte das Projekt kurzfristig umgesetzt werden. Alle Arbeiten wurden gut umgesetzt, so dass wir keine Beanstandungen hatten und ihn uneingeschränkt weiter empfehlen können.',
        author: 'Holger K. im Juli 2021'
    },
]

export const email = 'info@bsh-elektro.de'
export const mobile = '+49 (0) 151 100 396 49'
export const phone = '+49 (0) 66 21 96 88 932'
export const loxonePartnerWebsite = 'https://www.loxone.com/dede/partner/36199-rotenburg%20a.%20d.%20f./bio-smart-homes-gmbh--co-kg/'